import React, { PureComponent } from 'react';

class UseTip extends PureComponent {

    clickClose() {
        this.props.closeUseTip()
    }

    setExpries() {
        this.props.setExpires()
    }

    render() {
        const inlineStyle = {
            display : "block"
        }
        
        return (
            <React.Fragment>
            <div className="dimmed guide" style={inlineStyle}></div>
            <div className="layer_wrap guide" style={inlineStyle} onClick={() => this.clickClose()}>
                <div className="layer_inner">
                    <div className="layer_cont">
                        <div className="layer_btn_group">
                            { this.props.showExpiresBtn && (
                                <button type="button" className={this.props.lang == "en" ? "btn_never_view_en" : "btn_never_view"} onClick={() => this.setExpries()}>{this.props.uiTexts.text_41}</button>
                            )}
                            <button type="button" className="btn_ly_close" onClick={() => this.clickClose()}>{this.props.uiTexts.text_42}</button>
                        </div>
                        <img src={this.props.lang == "en" ? "img/NR_guide_en.png" : "img/NR_guide_ko.png"}  className="img_guide" alt="한양여대 챗봇 가이드"/>
                    </div>
                </div>
            </div>
            </React.Fragment>
        );
    }
};

export default UseTip;