import React, { PureComponent } from 'react';
class AlertFavorites extends PureComponent {
	constructor(props) {
		super(props);
		this.handleSetSetting = this.handleSetSetting.bind(this);
		this.handleClickClose = this.handleClickClose.bind(this);
	}

	componentDidMount() {}

	handleSetSetting() {
		this.props.setFavorite({ action: this.props.action, value: this.props.utterance });
		//this.props.setSetting()
		this.props.clickOverlay();
		this.props.close();
	}

	handleClickClose() {
		this.props.clickOverlay();
		this.props.close();
	}

	render() {
		const zIndex = {
			zIndex: '103',
		};
		let title;
		if (this.props.action === 'del') {
			title = this.props.uiTexts.text_15;
		}
		if (this.props.action === 'add') {
			title = this.props.uiTexts.text_12;
		}

		return (
			<div className="layer_wrap advisor_layer_wrap" style={zIndex}>
				<div className="layer_inner">
					<div className="layer_cont">
						<h2 className="layer_h_tit">
							<span className="ico_comm ico_star_on"></span>
							{title}
						</h2>
						{this.props.action === 'warning' ? (
							<strong className="layer_sub_tit" dangerouslySetInnerHTML={{ __html: this.props.uiTexts.text_14 }} />
						) : (
							<strong className="layer_sub_tit">"{this.props.utterance}"</strong>
						)}
						{this.props.action === 'del' && <p className="layer_txt">{this.props.uiTexts.text_16}</p>}
						{this.props.action === 'add' && <p className="layer_txt">{this.props.uiTexts.text_13}</p>}

						{this.props.action === 'warning' ? (
							<div className="btn_area">
								<button type="button" className="layer_btn" onClick={this.handleClickClose}>
									{this.props.uiTexts.text_29}
								</button>
							</div>
						) : (
							<div className="btn_area btn_area_half">
								<button type="button" className="layer_btn layer_btn_type" onClick={this.handleClickClose}>
									{this.props.uiTexts.text_31}
								</button>
								<button type="button" className="layer_btn" onClick={this.handleSetSetting}>
									{this.props.uiTexts.text_30}
								</button>
							</div>
						)}
					</div>
					<button type="button" className="btn_close" onClick={this.handleClickClose}>
						<span className="ico_comm ico_close">레이어 닫기</span>
					</button>
				</div>
			</div>
		);
	}
}

export default AlertFavorites;
