import { call, put, fork, takeLatest, takeEvery, delay } from 'redux-saga/effects';
import * as ActionTypes from '../modules/ActionTypes';
import * as messageApi from '../services/message';

//import { APP_CONFIG, NEED_LOGIN_TEMPLATE, DEFAULT_ERROR, DEFAULT_ERROR_CN, DEFAULT_ERROR_EN } from '../utils/config';
import { APP_CONFIG, NEED_LOGIN_TEMPLATE, DEFAULT_ERROR } from '../utils/config';

// GETS THE SESSION AND PUT IN THE SESSION STORE
function* get(action) {
	let template = DEFAULT_ERROR;
	// TODO 다국어에 맞추어서 추후 수정 필요
	/*
	if (action.payload.projectId === '14') {
		template = DEFAULT_ERROR_EN;
	} else if (action.payload.projectId === '15') {
		template = DEFAULT_ERROR_CN;
	}
	*/

	const { response, error } = yield call(messageApi.getGreeting, action.payload);
	if (response) {
		yield put({ type: ActionTypes.GET_GREETING.SUCCESS, payload: { response } });
	} else {
		console.error('[ERROR][getGreeting]', error);
		// 기본 비로그인 그리팅 전송
		const response = {
			data: {
				version: '2.0',
				template: template,
				data: {
					sessionId: '',
					projectId: action.payload.projectId,
					channelId: '',
					responseType: 'LOCAL_ANSWER',
					messageFromUser: '',
					scenarioyn: 'N',
					dialogTaskId: '',
					dialogTaskName: '',
					scenarioId: null,
					userName: null,
					isLogin: false,
				},
				state: {
					code: 0,
					detail: 'SUCCESS',
				},
			},
		};
		// yield put({type: ActionTypes.SEND_MESSAGE.ERROR, payload: { error }});
		// yield delay(5000);
		yield put({ type: ActionTypes.GET_GREETING.SUCCESS, payload: { response } });
	}
}

function* send(action) {
	let template = DEFAULT_ERROR;

	// TODO 다국어에 맞추어서 추후 수정 필요
	/*
	if (action.payload.projectId === '14') {
		template = DEFAULT_ERROR_EN;
	} else if (action.payload.projectId === '15') {
		template = DEFAULT_ERROR_CN;
	}
	*/
	//console.log(action)
	if (APP_CONFIG.useLogin && !action.payload.isLogin) {
		template = NEED_LOGIN_TEMPLATE;
	}
	const temp = {
		data: {
			version: '2.0',
			template: template,
			data: {
				sessionId: action.payload.sessionKey,
				projectId: action.payload.projectId,
				channelId: action.payload.channelId,
				responseType: 'LOCAL_ANSWER',
				messageFromUser: action.payload.utterance,
				scenarioyn: 'N',
				dialogTaskId: '',
				dialogTaskName: '',
				scenarioId: null,
				userName: null,
				isLogin: action.payload.isLogin,
			},
			state: {
				code: -1,
				detail: 'ERROR',
			},
		},
	};

	if ((APP_CONFIG.useLogin && action.payload.isLogin) || !APP_CONFIG.useLogin) {
		const { response, error } = yield call(messageApi.sendMessage, action.payload);

		if (response) {
			if (response.data.state.code === 0) {
				yield put({ type: ActionTypes.SEND_MESSAGE.SUCCESS, payload: { response } });
			} else {
				console.error('[ERROR][sendMessage]', response.data.state);
				yield delay(500);
				yield put({ type: ActionTypes.SEND_MESSAGE.SUCCESS, payload: { response: temp } });
			}
		} else {
			console.error('[ERROR][sendMessage]', error);
			yield delay(500);
			yield put({ type: ActionTypes.SEND_MESSAGE.SUCCESS, payload: { response: temp } });
		}
	} else {
		yield delay(500);
		yield put({ type: ActionTypes.SEND_MESSAGE.SUCCESS, payload: { response: temp } });
	}
}

function* getPush(action) {
    let template = DEFAULT_ERROR
	/*
    if (action.payload.projectId === "14") {
        template = DEFAULT_ERROR_EN
    } else if (action.payload.projectId === "15") {
        template = DEFAULT_ERROR_CN
    }
	*/

    const temp = { 
        "data" : { 
            "version": "2.0",
            "template": template,
            "data": {
                "sessionId": action.payload.sessionKey,
                "projectId": action.payload.projectId,
                "channelId": action.payload.channelId,
                "responseType": "LOCAL_ANSWER",
                "messageFromUser": action.payload.utterance,
                "scenarioyn": "N",
                "dialogTaskId": "",
                "dialogTaskName": "",
                "scenarioId": null,
                "userName": null,
                "isLogin": action.payload.isLogin
            },
            "state": {
                "code": -1,
                "detail": "ERROR"
            }
        }
    }

	const { response, error } = yield call(messageApi.pushMessage, action.payload);

	if (response) {
		if (response.data.state.code === 0) {
			yield put({type: ActionTypes.PUSH_MESSAGE.SUCCESS, payload: { response }});
		} else {
			console.error('[ERROR][pushMessage]',response.data.state)
			yield delay(500)
			yield put({type: ActionTypes.PUSH_MESSAGE.SUCCESS, payload: { response : temp  }});
		}
	} else {
		console.error('[ERROR][pushMessage]',error)
		yield delay(500)
		yield put({type: ActionTypes.PUSH_MESSAGE.SUCCESS, payload: { response : temp }});
	}  
}

function* watchGet() {
	yield takeLatest(ActionTypes.GET_GREETING.REQUEST, get);
	yield takeEvery(ActionTypes.SEND_MESSAGE.REQUEST, send);
	yield takeEvery(ActionTypes.PUSH_MESSAGE.REQUEST, getPush);
}

export default function* messageSaga() {
	yield fork(watchGet);
}
