import React, { PureComponent } from 'react';
import {nl2br} from '../../utils/utils'

class Alert extends PureComponent {
    constructor(props) {
        super(props);

        this.handleClickClose = this.handleClickClose.bind(this);
    }

    componentDidMount() {

    }

    handleClickClose() {
        this.props.close()
    }

    render() {
        const zIndex = {
            zIndex : "103"
        }
        return (
            <div className="layer_wrap advisor_layer_wrap" style={zIndex}>
            <div className="layer_inner">
            <div className="layer_cont">
                <strong className="layer_sub_tit">&nbsp;</strong>
                <p className="layer_txt" dangerouslySetInnerHTML={{__html:nl2br(this.props.message)}}/>
                <div className="btn_area">
                    <button type="button" className="layer_btn" onClick={this.handleClickClose}>{this.props.uiTexts.text_29}</button>
                </div>
            </div>
            <button type="button" className="btn_close" onClick={this.handleClickClose}><span className="ico_comm ico_close">레이어 닫기</span></button> 
            </div>
            </div>
        );
    }
};

export default Alert;