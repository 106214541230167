import React, { PureComponent } from 'react';
import ButtonItem from './ButtonItem';
import { APP_CONFIG } from '../../../utils/config';
import { openUrl } from '../../../utils/utils';
class ListCard extends PureComponent {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	link(url) {
		openUrl(url, APP_CONFIG.popWinTarget, this.props.fromApp);

		/*
        const popWin = window.open(url, APP_CONFIG.popWinTarget)
        popWin.focus()
        */
	}

	removeHighlightTags(data) {
		return data.replace(/<!HS>/g, '').replace(/<!HE>/g, '');
	}

	render() {
		let { header, items } = this.props.data;
		const buttons = <ButtonItem data={this.props.data.buttons} fromApp={this.props.fromApp} />;
		return (
			<div className="bot_box">
				<div className="bot_txt ui_list">
					{header.title && <div className="ui_top">{header.title}</div>}
					<div className="ui_cont">
						<ul className="list_box">
							{items.map((item, index) => (
								<li key={index}>
									<div className="list_tit_box">
										{item.link ? (
											item.link.web !== null &&
											item.link.web !== '' && (
												<a
													href="#none"
													onClick={() => {
														this.link(item.link.web);
													}}
													title="새창열림"
												>
													<strong>{this.removeHighlightTags(item.title)}</strong>
													<span className="sub_txt">{item.description}</span>
												</a>
											)
										) : (
											<div>
												<strong>{this.removeHighlightTags(item.title)}</strong>
												<span className="sub_txt">{item.description}</span>
											</div>
										)}
									</div>
								</li>
							))}
						</ul>
					</div>
					{buttons}
				</div>
			</div>
		);
	}
	/*

    */
}

export default ListCard;
