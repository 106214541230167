/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { PureComponent } from 'react';
import { APP_CONFIG } from '../../utils/config';

class SideMenu extends PureComponent {
	constructor(props) {
		super(props);

		this.handleClickClose = this.handleClickClose.bind(this);
		this.handleClickTip = this.handleClickTip.bind(this);
		this.handleAlert = this.handleAlert.bind(this);
		this.handleClickSetting = this.handleClickSetting.bind(this);
		this.handleQuickMenuClick = this.handleQuickMenuClick.bind(this);
		//this.handleClickLoginAlert = this.handleClickLoginAlert.bind(this)
	}

	componentDidMount() { 
		let quickMenuLis = document.getElementsByClassName('sub_menu_item')
		if (quickMenuLis.length > APP_CONFIG.maxSetting) {
			for (let i = 0; i < quickMenuLis.length; i++) {
				if (i > APP_CONFIG.maxSetting-1) quickMenuLis[i].style.display = "none";
			}
		}
	}

	state = {
		isOpenTheme: false,
	};

	handleQuickMenuClick(e) {
		// this.totalClose()
		this.props.close();
		let sendData = {
			utterance: e.target.value,
			sessionKey: this.props.sessionKey,
			channelId: this.props.channelId,
			isLogin: this.props.isLogin,
			isDev: this.props.config.isDev,
			projectId: this.props.config.projectId,
		};

		this.props.send(sendData);
	}

	handleClickClose() {
		// this.totalClose()
		if (this.props.isLogin) {
			this.props.setSetting();
		}
		this.props.close();
	}


    handleClickTip() {
        // this.totalClose()
		/*
        this.props.close()

        let sendData = {
            "utterance" : '이용팁', 
            "sessionKey" : this.props.sessionKey,
            "channelId" : this.props.channelId,
            "isLogin" : this.props.isLogin
        }

        this.props.send(sendData)
		*/
		this.props.openUseTip();
    }


	handleClickSetting() {
		//if (this.props.isLogin) {
			this.props.openSetting();
			/*
		} else {
			this.props.loginAlert();
		}
		*/
	}

	handleAlert() {
		alert('준비중입니다.');
	}

	clickPosition(value) {
		this.props.setMenuPostion(value);
	}

	openThemeSet() {
		const currentState = this.themeSetDiv.style.display;
		//console.debug(currentState);
		if (currentState === '' || currentState === 'none') {
			this.themeSetDiv.style.display = 'block';
			this.setState({ isOpenTheme: true });
		} else {
			this.themeSetDiv.style.display = 'none';
			this.setState({ isOpenTheme: false });
		}
	}
	/*
    <a href="#" className="top_menu fL"><img src="img/ico_close.png" alt="닫기" /></a>
    <a href="#" className="btn_logout left"><i className="fas fa-sign-out-alt"></i><span>로그아웃</span></a>
    <a href="#" className="btn_login left"><i className="fas fa-user-circle mR5"></i>로그인</a>
    */

	render() {
		//한양여대 SSO 로그인/로그아웃시 프로젝트id, lang 정보 전달 방법으로 사용
		//index.jsp에서 처리로직 추가(한양여대)
		APP_CONFIG.logIn  += "%2F%3FprojectId%3D" + this.props.config.projectId + "%26lang%3D" +this.props.config.language;
		APP_CONFIG.logOut += "%2F%3FprojectId%3D" + this.props.config.projectId + "%26lang%3D" +this.props.config.language; 
		
		/*
		let topMenuClass = 'sub_menu_inner right';
		if (this.props.menuPosition === 'left') {
			topMenuClass = 'sub_menu_inner left';
		}
		let themeName = '파랑';
		if (this.props.theme === 'theme_green') themeName = '초록';
		if (this.props.theme === 'theme_purple') themeName = '보라';
		if (this.props.theme === 'theme_red') themeName = '빨강';
        */
		let subBtnClassName = 'sub_btn_group'
		if (this.props.isLogin) subBtnClassName = 'sub_btn_group w_half'

		return (
			//<div className="sub_menu_box">
				<div className="sub_menu_inner" ref={(ref) => { this.sidemenuElement = ref;}}>
					<div className="profile_box">
						{this.props.isLogin ? ( //로그인인 경우
							<React.Fragment>
								<span className="chat_logo_box">
									<img src="img/profile_after.png" alt="" />
								</span>
								<span className="profile_name">{this.props.userName}</span>
								<a href={APP_CONFIG.logOut} className="logout_link">
									<span className="ico_comm">{this.props.uiTexts.text_28}</span>
								</a>
							</React.Fragment>
						) : (
							//로그인이 아닌 경우
							<React.Fragment>
								<span className="chat_logo_box">
									<img src="img/profile_before.png" alt="" />
								</span>
								<a href={APP_CONFIG.logIn} className="profile_name login" >
									{this.props.uiTexts.text_24}
								</a>
							</React.Fragment>
						)}
						<div className={subBtnClassName}>
							{ this.props.isLogin ? 
								(
									<React.Fragment>
										<button type="button" className={this.props.config.language == "en" ? "btn login setting" : "btn login"} onClick={this.handleClickSetting}><span className="ico_setting"></span>{this.props.uiTexts.text_02}</button>
										<button type="button" className={this.props.config.language == "en" ? "btn login tip" : "btn login"} onClick={this.handleClickTip}><span className="ico_info"></span>{this.props.uiTexts.text_38}</button>	
									</React.Fragment>
								)
								:
								(<button type="button" className="btn" onClick={this.handleClickTip}><span className="ico_info"></span>{this.props.uiTexts.text_38}</button>)
							}
							
						</div>
					</div>

					{this.props.quickMenu && ( //퀵메뉴 표시
						<ul className="sub_menu_list">
							{this.props.quickMenu.map(
								(button, index) =>
									button.isUse === '1' && (
										<li className="sub_menu_item" key={index}>
											<button
												type="button"
												value={button.utterance}
												className="sub_menu_link"
												onClick={this.handleQuickMenuClick}
											>
												{button.label}
											</button>
										</li>
									),
							)}
						</ul>
					)}
					

					<button type="button" className="btn_close" onClick={this.handleClickClose}>
						<span className="ico_comm ico_close">메뉴 레이어 닫기</span>
					</button>
				</div>
			//</div>
		);
	}
}

export default SideMenu;
