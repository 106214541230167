import { request, chatbotUrl}  from '../utils/request';
import { APP_CONFIG } from '../utils/config';

export function getSetting(props) {
    const requestUrl = chatbotUrl(props.config.isDev) + APP_CONFIG.getSetting;

    const params = {
        "projectId" : props.projectId === undefined ? props.config.projectId : props.projectId
    }


    return request(requestUrl, 'post', params , {timeout : APP_CONFIG.timeout});
}

export function setSetting(data) {
    const requestUrl = chatbotUrl(data.isDev) + APP_CONFIG.setSetting;

    const params = {
        "projectId" : data.projectId,
        "setting" : data.settingData
    }

    return request(requestUrl, 'post', params, {timeout : APP_CONFIG.timeout});
}