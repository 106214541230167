import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as messageActions from '../../../modules/message';
import { APP_CONFIG } from '../../../utils/config';
import { openUrl, getLink } from '../../../utils/utils';

class ButtonItem extends PureComponent {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);

		this.state = {
			isMportalOpen: false,
			isEportalOpen: false,
		};
	}

	linkMessage(q) {
		let sendData = {
			utterance: q,
			sessionKey: this.props.message.sessionKey,
			channelId: this.props.message.channelId,
			isLogin: this.props.message.isLogin,
			isDev: this.props.config.isDev,
			blockId: '',
			projectId: this.props.config.projectId,
		};

		this.props.send(sendData);
		// console.log("Button Message Click!!!")
	}

	linkBlockId(blockId, q) {
		let sendData = {
			utterance: q,
			sessionKey: this.props.message.sessionKey,
			channelId: this.props.message.channelId,
			isLogin: this.props.message.isLogin,
			isDev: this.props.config.isDev,
			blockId: blockId,
			projectId: this.props.config.projectId,
		};

		this.props.send(sendData);
		// console.log("Button BlockId Click!!!")
	}

	linkPhone(phoneNum) {
		//앱에서의 전화와 앱에서의 전화를 구분
		if (this.props.fromApp) {
			window.openUrl('tel', 'tel:' + phoneNum);
		} else {
			document.location.href = 'tel:' + phoneNum;
		}
	}

	clickWebLink(url, target) {
		let windowTarget = target;
		if (windowTarget === undefined || windowTarget === '') windowTarget = APP_CONFIG.popWinTarget;

		/*
		if (url.indexOf('mportal.ewha.ac.kr') > -1) {
			url = 'https://mportal.ewha.ac.kr/mportal/main/default?redirect=' + url;
			
			if (!this.isMportalOpen) {
				let tempWin = window.open('https://mportal.ewha.ac.kr/mportal/main/default', windowTarget)
				tempWin.close()
				this.setState ({isMportalOpen : true})
			}
			
		}
		*/
		/*
		if (url.indexOf('eureka.ewha.ac.kr') > -1) {
			//if (windowTarget === undefined) windowTarget = 'eureka';
			if (!this.isEportalOpen) {
				window.open('https://eportal.ewha.ac.kr', windowTarget);
				//tempWin.close();
				this.setState({ isEportalOpen: true });
				setTimeout(() => {
					openUrl(url, windowTarget, this.props.fromApp);
				}, 1000);
			} else {
				openUrl(url, windowTarget, this.props.fromApp);
			}
		} else if (url.indexOf('mportal.ewha.ac.kr') > -1) {
			//if (windowTarget === undefined) windowTarget = 'eureka';
			if (!this.isMportalOpen) {
				window.open('https://mportal.ewha.ac.kr/mportal/main/default', windowTarget);
				//tempWin.close();
				this.setState({ isMportalOpen: true });
				setTimeout(() => {
					openUrl(url, windowTarget, this.props.fromApp);
				}, 1000);
			} else {
				openUrl(url, windowTarget, this.props.fromApp);
			}
		} else {
			openUrl(url, windowTarget, this.props.fromApp);
		}
		*/
		openUrl(url, windowTarget, this.props.fromApp);
		//console.info('[LINK URL]', url);
		//console.info('[LINK TARGET]', windowTarget);
	}

	render() {
		let buttons = '';
		if (this.props.data !== null && this.props.data !== undefined && this.props.data !== '' && this.props.data.length > 0) {
			buttons = this.props.data.map((button, index) =>
				button.action === 'webLink' ? (
					<a className="btn_link" href="#none" onClick={() => this.clickWebLink(getLink(button.webLinkUrl), button.target)} key={index}>
						{button.label}
					</a>
				) : button.action === 'message' ? (
					<a
						className="btn_link"
						href="#none"
						onClick={() => {
							this.linkMessage(button.messageText);
						}}
						key={index}
					>
						{button.label}
					</a>
				) : button.action === 'phone' ? (
					<a
						className="btn_link"
						href="#none"
						onClick={() => {
							this.linkPhone(button.phoneNumber);
						}}
						key={index}
					>
						{button.label}
					</a>
				) : (
					button.action === 'block' && (
						<a
							className="btn_link"
							href="#none"
							onClick={() => {
								this.linkBlockId(button.blockId, button.label);
							}}
							key={index}
						>
							{button.label}
						</a>
					)
				),
			);
		}
		return <div className="btn_group">{buttons}</div>;
	}
}

function mapStateToProps(state) {
	return { ...state };
}

function mapDispatchToProps(dispatch) {
	return { send: (payload) => dispatch(messageActions.sendMessage(payload)) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonItem);
