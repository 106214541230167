import React, { PureComponent } from 'react';
import QuestionBalloon from './Balloon/QuestionBalloon';
import BotMessages from './BotMessages';
import Loading from './Balloon/LoadingBalloon';

class ChatMessageList extends PureComponent {
	componentDidUpdate(prevProps, prevState) {
		// THIS PART HANDLES AUTO SCROLLING AFTER DATA FETCHING
		if (prevProps.data.data.length !== this.props.data.data.length) {
			let botElements = document.getElementsByClassName('bot_wrap')
			let lastScrollHeight = botElements[botElements.length-1].scrollHeight + 85;

			this.element.scrollTop = this.element.scrollHeight - lastScrollHeight;
			//console.info(this.botElement)
		}
	}

	render() {
		//const toggleLoding = this.props.message.isLoading
		//console.info(this.props)

		return (
			<div
				className="chat_container"
				ref={(ref) => {
					this.element = ref;
				}}
				onScroll={this.handleScroll}
			>
				<div className="chat_content">
					{this.props.data.data.map((message, idx) =>
						message.outputs ? (
							<BotMessages
								data={message.outputs}
								isGreeting={this.props.data.isGreeting}
								quickBtns={message.quickReplies}
								isDev={this.props.isDev}
								uiTexts={this.props.uiTexts}
								reload={this.props.reload}
								key={idx}
							/>
						) : (
							message.input && <QuestionBalloon key={idx} data={message.input} favorite={this.props.favorite} />
						),
					)}
					{this.props.data.isLoading && <Loading uiTexts={this.props.uiTexts} />}
				</div>
			</div>
		);
	}
}

export default ChatMessageList;
