import { request, chatbotUrl}  from '../utils/request';
import { APP_CONFIG } from '../utils/config';

export default function getRealtimeAnswer(props) {
    
    const params = {
        projectId: props.projectId === undefined ? props.config.projectId : props.projectId
    }
    
    const requestUrl = chatbotUrl(props.config.isDev) + APP_CONFIG.getRealtimeAnswer;
    return request(requestUrl, 'post', params);
}