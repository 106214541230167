import { combineReducers } from 'redux';
import message from './message';
import ui from './ui';
import ark from './ark';
import setting from './setting'
import feedback from './feedback'
import config from './config'

const rootReducer = combineReducers({
    message, 
    ui, 
    ark,
    setting,
    feedback,
    config,
});

export default rootReducer;