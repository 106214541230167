import { request, chatbotUrl}  from '../utils/request';
import { APP_CONFIG } from '../utils/config';

export default function getArk(reqData) {
    let projectId = reqData.projectId
    //if (projectId === "13") projectId = ""
    const params = {
        "query" : reqData.value,
        "projectId" : projectId
    }

    const requestUrl = chatbotUrl(reqData.isDev) + APP_CONFIG.getArk;
    return request(requestUrl, 'post', params);
}