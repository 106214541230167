import createRequestTypes from '../utils/action';

/* GET GREETING */
export const GET_GREETING = createRequestTypes('GET_GREETING');

/* MESSAGE */
export const SEND_MESSAGE = createRequestTypes('SEND_MESSAGE');
export const PUSH_MESSAGE = createRequestTypes('PUSH_MESSAGE');
export const INIT_MESSAGE = "INIT_MESSAGE"

/* ARK */
export const GET_ARK = createRequestTypes('GET_ARK');
export const INIT_ARK = "INIT_ARK"

/* REALTIME ANSWER */
export const GET_REALTIME_ANSWER = createRequestTypes('GET_REALTIME_ANSWER');

/* UI */
export const OPEN_SIDEMENU = "OPEN_SIDEMENU"
export const CLOSE_SIDEMENU = "CLOSE_SIDEMENU"
export const OPEN_SETTING = "OPEN_SETTING"
export const CLOSE_SETTING = "CLOSE_SETTING"
export const OPEN_FEEDBACK = "OPEN_FEEDBACK" 
export const CLOSE_FEEDBACK = "CLOSE_OPEN_FEEDBACK"
export const OPEN_AGREE_CONTENT = "OPEN_AGREE_CONTENT"
export const CLOSE_AGREE_CONTENT = "CLOSE_AGREE_CONTENT"
export const OPEN_LOGIN_ALERT = "OPEN_LOGIN_ALERT"
export const CLOSE_LOGIN_ALERT = "CLOSE_LOGIN_ALERT"
export const CLICK_OVERLAY = "CLICK_OVERLAY"
export const TOGGLE_OVERLAY = "TOGGLE_OVERLAY"
export const SHOW_REALTIME_ANSWER = "SHOW_REALTIME_ANSWER"

export const TOGGLE_FAVORITES = "TOGGLE_FAVORITES"
export const OPEN_FAVORITE_ALERT = "OPEN_FAVORITE_ALERT"
export const CLOSE_FAVORITE_ALERT = "CLOSE_FAVORITE_ALERT"

export const SET_UI_TEXTS = "SET_UI_TEXTS"

export const TOGGLE_USETIP = "TOGGLE_USETIP"

/* SETTING */
export const GET_SETTING = createRequestTypes('GET_SETTING');
export const SET_SETTING = createRequestTypes('SET_SETTING');
export const USE_ARK = "USE_ARK"
export const MENU_POSITION = "MENU_POSITION"
export const TOGGLE_GREETING = "TOGGLE_GREETING"
export const TOGGLE_QUICKMENU = "TOGGLE_QUICKMENU"
export const SET_FAVORITE = "SET_FAVORITE"
export const SET_THEME = "SET_THEME"

/* FEEDBACK */
export const WRITE_FEEDBACK = createRequestTypes('WRITE_FEEDBACK');
export const CLEAR_FEEDBACK_ALERT = "CLEAR_FEEDBACK_ALERT"

/* CONFIG */
export const SET_PROJECT = "SET_PROJECT"
export const SET_LANG = "SET_LANG"
export const SET_IS_DEV = "SET_IS_DEV"