import React, { PureComponent } from 'react';

class OverLay extends PureComponent {
    
    constructor(props) {
        super(props);
        this.handleClickClose = this.handleClickClose.bind(this);
    }

    componentDidMount() {

    }

    handleClickClose() {
        if (this.props.isViewAgree === false && this.props.isLoginAlert === false) {
            this.props.click()
        }
    }

    render() {
        const inlineSytle = {
            zIndex : this.props.zIndex
        }
        return (
            <div className="dimmed" style={inlineSytle} onClick={this.handleClickClose}></div>
        );
    }
};

export default OverLay;