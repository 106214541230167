import { all } from 'redux-saga/effects'
import message from './message';
import ark from './ark'
import setting from './setting'
import feedback from './feedback'
import realtimeAnswer from './realtimeAnswer'
// import session from './session';

// LOAD THE SAGA
export default function* rootSaga() {
  // yield all ([ message(), session() ])
  yield all([
    message(), ark(), setting(), feedback(), realtimeAnswer()
  ])
}