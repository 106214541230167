import React, { PureComponent } from 'react';
import { APP_CONFIG } from '../../utils/config'

class LoginAlert extends PureComponent {

    handleClickClose() {
        if (this.props.clickSideMenu === false) {
            this.props.clickOverlay()
        }
        this.props.close()
    }

    loginLink() {
        document.location.href=APP_CONFIG.logIn
    }

    render() {
        const zIndex = {
            zIndex : "103"
        }
        return (
            <div className="layer_wrap advisor_layer_wrap"  style={zIndex}>
            <div className="layer_inner">
            <div className="layer_cont">
                <strong className="layer_sub_tit">{this.props.uiTexts.text_01}</strong>
                <div className="btn_area">
                    <button type="button" className="layer_btn" onClick={() => this.loginLink()}>{this.props.uiTexts.text_24}</button>
                </div>
            </div>
            <button type="button" className="btn_close" onClick={() => this.handleClickClose()}><span className="ico_comm ico_close">레이어 닫기</span></button> 
            </div>
            </div>
        );
    }
};

export default LoginAlert;




