import React, { PureComponent } from 'react';

class Feedback extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			defaultTxt: this.props.uiTexts.text_21,
			allowYn: 'N',
		};
		this.handleClickClose = this.handleClickClose.bind(this);
		this.handleClickTextarea = this.handleClickTextarea.bind(this);
		this.handleChangeInput = this.handleChangeInput.bind(this);
		this.handelClickSend = this.handelClickSend.bind(this);
	}

	componentDidMount() {}

	handleClickClose() {
		this.props.close();
	}

	handleClickTextarea(e) {
		if (e.type === 'click') {
			e.target.placeholder = '';
		}
		if (e.type === 'blur') {
			if (e.target.value.trim() === '') {
				e.target.value = '';
				e.target.placeholder = this.state.defaultTxt;
			}
		}
	}

	handleClickViewAgree() {
		if (this.inputElement.checked === false) {
			this.inputElement.checked = true;
			this.setState({ allowYn: 'Y' });
			this.props.view();
		} else {
			this.inputElement.checked = false;
			this.setState({ allowYn: 'N' });
		}
	}

	handleChangeInput(e) {
		if (e.target.checked === true) {
			this.setState({ allowYn: 'Y' });
			this.props.view();
		} else {
			this.setState({ allowYn: 'N' });
		}
	}

	handelClickSend() {
		if (this.textareaElement.value.trim() === '') {
			this.props.alert('의견을 입력해 주십시요');
		} else {
			const writeJSON = {
				projectId: this.props.config.projectId,
				//"allowYn" : this.state.allowYn,
				allowYn: 'N',
				feedbackContent: this.textareaElement.value,
				isDev: this.props.config.isDev,
			};

			this.props.write(writeJSON);
		}
	}

	render() {
		/*
        const startDate = new Date('2021/03/17/09:00:00')
        const endDate = new Date('2021/03/24/18:00:00')
        const toDay = new Date()
        let isEvent = false;
        if (startDate < toDay && toDay < endDate) {
            isEvent = true;
        }
        */
		return (
			<div className="layer_wrap layer_wrap_type2 advisor_layer_wrap">
				<div className="layer_inner">
					<div className="layer_cont">
						<h2 className="layer_h_tit" dangerouslySetInnerHTML={{ __html: this.props.uiTexts.text_17 }} />
						<strong className="layer_sub_tit" dangerouslySetInnerHTML={{ __html: this.props.uiTexts.text_18 }} />
						<label htmlFor="opinionTxt" className="lbl_txt">
							{this.props.uiTexts.text_19}
						</label>
						<div className="inp_textarea_box">
							<textarea
								id="opinionTxt"
								className="inp_textarea"
								placeholder={this.state.defaultTxt}
								onClick={this.handleClickTextarea}
								onBlur={this.handleClickTextarea}
								ref={(textarea) => {
									this.textareaElement = textarea;
								}}
							/>
						</div>
						<p className="layer_txt">{this.props.uiTexts.text_20}</p>
						{/*
                <p className="layer_txt">
                    <input type="checkbox" onChange={this.handleChangeInput} ref={(checkbox)=>{this.inputElement = checkbox}}/> 
                    <a href="#none" onClick={() => this.handleClickViewAgree()}> <strong>(선택) 이벤트 참여를 위한 개인정보 수집 및 이용 동의</strong></a>
                </p>
                */}
						<div className="btn_area">
							<button type="button" className="layer_btn" onClick={this.handelClickSend}>
								{this.props.uiTexts.text_33}
							</button>
						</div>
					</div>
					<button type="button" className="btn_close" onClick={this.handleClickClose}>
						<span className="ico_comm ico_close">레이어 닫기</span>
					</button>
				</div>
			</div>
		);
	}
}

export default Feedback;
