import { createAction } from 'redux-actions';
import * as ActionTypes from './ActionTypes';

export const getGreeting = createAction(ActionTypes.GET_GREETING.REQUEST);
export const sendMessage = createAction(ActionTypes.SEND_MESSAGE.REQUEST);
export const pushMessage = createAction(ActionTypes.PUSH_MESSAGE.REQUEST);
export const initMessage = createAction(ActionTypes.INIT_MESSAGE);

const initialState = {
    "sessionKey" : null,   //세션키
    "channelId" : null,
    "data" : [],   //리스폰스 데이터
    "isLogin" : false, //로긴여부
    "scenarioYn" : "N",
    "dialogTaskId" : null,
    "userName" : null,
    "isLoading" : false,
    "isGreeting" : false,
    "init" : false,
    "clearTextbox" : false,
    "responseType" : "ANSWER"
}

function message(state = initialState, action) {
    //console.info('[reducers.message.message][action]', action)
    switch(action.type) {
        case ActionTypes.INIT_MESSAGE: 
            //return action.payload
            const _data = action.payload.initData
            return  {
                ...state,
                sessionKey:action.payload.sessionKey,
                data: _data
            }
            
        case ActionTypes.GET_GREETING.REQUEST: 
            return {
                ...state,
                isLoading:true,
            };
        case ActionTypes.GET_GREETING.SUCCESS:  // 그리팅을 성공적으로 받았을 경우
            return {
                ...state,
                sessionKey:action.payload.response.data.data.sessionId,
                channelId:action.payload.response.data.data.channelId,
                data: [...state.data, action.payload.response.data.template],
                isLogin:action.payload.response.data.data.isLogin,
                userName:action.payload.response.data.data.userName,
                isLoading:false,
                isGreeting: true,
                isPushMessage: false,
                init: true
            };
        case ActionTypes.PUSH_MESSAGE.REQUEST: 
            return {
                ...state,
                isLoading:true,
            };
        case ActionTypes.PUSH_MESSAGE.SUCCESS:  
            return {
                ...state,
                sessionKey:action.payload.response.data.data.sessionId,
                data: [...state.data, action.payload.response.data.template],
                isLogin:action.payload.response.data.data.isLogin,
                userName:action.payload.response.data.data.userName,
				responseType : action.payload.response.data.data.responseType,
                isLoading:false,
				isGreeting: false,
				isPushMessage: true,
            };
        case ActionTypes.SEND_MESSAGE.REQUEST:  // 메세지을 보낼 때 state 변경
            const template = {
                input : {
                    utterance : action.payload.utterance
                }
            }
            return {
                ...state,
                data: [...state.data, template],
                isLoading:true,
                clearTextbox : true,
        };
        case ActionTypes.SEND_MESSAGE.SUCCESS:  // 메세지을 성공적으로 받았을 경우
            return {
                ...state,
                sessionKey:action.payload.response.data.data.sessionId,
                data: [...state.data, action.payload.response.data.template],
                scenarioYn: action.payload.response.data.data.scenarioyn,
                dialogTaskId: action.payload.response.data.data.dialogTaskId,
                isLogin:action.payload.response.data.data.isLogin,
                isLoading:false,
                isGreeting: false,
                isPushMessage: false,
                clearTextbox : false,
                responseType : action.payload.response.data.data.responseType
            };
        default:
            return state;
    }
}

export default message;