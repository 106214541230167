import React, { PureComponent } from 'react';
import { nl2br } from '../../../utils/utils';
import ButtonItem from './ButtonItem';

class SimpleText extends PureComponent {
	render() {
		let inStyle;
		if (this.props.isCarousel) {
			inStyle = {
				width: '236px',
				minWidth: '236px'
			};
		}
		let wordBreak = {
			wordBreak: "break-word"
		}
		return (
			<div className="bot_box">
				<div className="bot_txt"  style={inStyle}>
					<div style={wordBreak} dangerouslySetInnerHTML={{ __html: nl2br(this.props.data.text) }} />
					<ButtonItem data={this.props.data.buttons} />
				</div>
			</div>
		);
	}
}

export default SimpleText;
