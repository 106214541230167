import { call, put, fork, takeLatest } from 'redux-saga/effects'
import * as ActionTypes from '../modules/ActionTypes';
import {wirteFeedback} from '../services/feedback';

function* callWirteFeedback(action) {
    const { response, error } =  yield call(wirteFeedback, action.payload);

    if (response) {
        yield put({type: ActionTypes.WRITE_FEEDBACK.SUCCESS, payload: { response }});
    } else {
        yield put({type: ActionTypes.WRITE_FEEDBACK.FAILURE, payload: { error }});
        console.error('[ERROR][wirteFeedback]', error)
    }
}

function* watchGet() {
    yield takeLatest(ActionTypes.WRITE_FEEDBACK.REQUEST, callWirteFeedback);
}

export default function* feedbackSaga() {
    yield fork(watchGet);
}