import { request, chatbotUrl}  from '../utils/request';
import {APP_CONFIG} from '../utils/config';

const defalutParams = {
  userRequest: {
    timezone: "Asia/Seoul",
    utterance: "",
    lang: "kr",
    block: {
      id: "",
      name: ""
    },
    user: {
      id: "",
      type: "botUserKey",
      properties: {
        channelCode: APP_CONFIG.channelCode,
        dialogTaskId: ""
      }
    }
  },
   bot : {
     id : APP_CONFIG.projectId
  }
}

export function getGreeting(props) {
  const requestUrl = chatbotUrl(props.config.isDev) + APP_CONFIG.getGreeting;
  //console.info("[props]", props)
  let params = {
    ...defalutParams,
      userRequest : {
        ...defalutParams.userRequest,
        user : {
          ...defalutParams.userRequest.user,
          id : props.message.sessionKey === undefined || props.message.sessionKey === null ? '' : props.message.sessionKey
        }
      },
    bot: {
      id : props.projectId === undefined ? props.config.projectId : props.projectId
    }
  }
  return request(requestUrl, 'post', params, {timeout : APP_CONFIG.timeout});
}

export function sendMessage(sendData) {
  let {utterance, sessionKey, blockId, blockName, projectId, isDev} = sendData;
  const requestUrl = chatbotUrl(isDev) + APP_CONFIG.sendQuestion;
  let params;
  if (blockName === undefined || blockName === null || blockName === "" ) {
    blockName = utterance
  }

  if (blockId === "") {
    params = {
      ...defalutParams,
      userRequest : {
        ...defalutParams.userRequest,
        utterance: utterance,
        user : {
          ...defalutParams.userRequest.user,
          id : sessionKey
        }
      },
      bot: {
        id : projectId
      }
    }
  } else {
    params = {
      ...defalutParams,
      userRequest : {
        ...defalutParams.userRequest,
        utterance: utterance,
        block: {
          id: blockId,
          name: blockName
        },
        user : {
          ...defalutParams.userRequest.user,
          id : sessionKey
        }
      },
      bot : {
        id : projectId
      }
    }
  }
  // console.debug("[PARAMS]", params)
  
  return request(requestUrl, 'post', params, {timeout : APP_CONFIG.timeout});
}

export function pushMessage(sendData) {
	let {sessionKey, projectId, isDev} = sendData;
	const requestUrl = chatbotUrl(isDev) + APP_CONFIG.getPush;
	let params;

	params = {
		...defalutParams,
		userRequest : {
			...defalutParams.userRequest,
			user : {
				...defalutParams.userRequest.user,
				id : sessionKey
			}
		},
		bot : {
			id : projectId
		}
	}
	//console.info('[push request params]', params)
	//console.info('[push sendData]', sendData)

	return request(requestUrl, 'post', params, {timeout : APP_CONFIG.timeout});
} 