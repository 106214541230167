import React, { PureComponent } from 'react';
//import { connect } from 'react-redux';
//import * as uiAction from '../../modules/ui';
import { getTalkTime } from '../../utils/utils';

class QuestionBalloon extends PureComponent {
	render() {
		return (
			<div className="user_wrap">
				<div className="user_box">
					<div className="user_txt">{this.props.data.utterance}</div>
					<span className="time user">{getTalkTime()}</span>
				</div>
			</div>
		);
	}
}

/*
function mapStateToProps(state) {
	return { ...state };
}

function mapDispatchToProps(dispatch) {
	return {
		openFavoriteAlert: (payload) => dispatch(uiAction.openFavoriteAlert(payload)),
		controlOverlay: (payload) => dispatch(uiAction.controlOverlay(payload)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionBalloon);
*/
export default QuestionBalloon;
