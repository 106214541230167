import { call, put, fork, takeLatest } from 'redux-saga/effects'
import * as ActionTypes from '../modules/ActionTypes';
import getArk from '../services/ark';

function* callGetArk(action) {
    //yield delay(100)
    const { response, error} =  yield call(getArk, action.payload);

    if (response) {
        yield put({type: ActionTypes.GET_ARK.SUCCESS, payload: { response }});
    } else {
        console.error("[ERROR][getArk]", error)
    }
}

function* watchGet() {
    yield takeLatest(ActionTypes.GET_ARK.REQUEST, callGetArk);
}

export default function* arkSaga() {
    yield fork(watchGet);
}