import React, { PureComponent } from 'react';
import Flicking from '@egjs/react-flicking';
import { connect } from 'react-redux';
import * as messageActions from '../modules/message';
import { APP_CONFIG } from '../utils/config';
import { openUrl, getLink } from '../utils/utils';

class QuickReplies extends PureComponent {
	clickSend(value) {
		let sendData = {
			utterance: value,
			sessionKey: this.props.message.sessionKey,
			channelId: this.props.message.channelId,
			isLogin: this.props.message.isLogin,
			isDev: this.props.config.isDev,
			projectId: this.props.config.projectId,
		};

		this.props.send(sendData);
	}

	clickLink(url, target) {
		let windowTarget = target;
		if (windowTarget !== undefined || windowTarget === '') windowTarget = APP_CONFIG.popWinTarget;
		openUrl(url, windowTarget, this.props.fromApp);
	}

	render() {
		const quickReplies = this.props.data.map((item, index) =>
			item.action === 'webLink' ? (
				<div className="query_box" key={index}>
					<a
						href="#none"
						className="word"
						onClick={() => {
							this.clickLink(getLink(item.webLinkUrl), item.target);
						}}
					>
						{item.label}
					</a>
				</div>
			) : (
				item.action === 'message' && (
					<div className="query_box" key={index}>
						<a
							href="#none"
							className="word"
							onClick={() => {
								this.clickSend(item.messageText);
							}}
						>
							{item.label}
						</a>
					</div>
				)
			),
		);

		return (
			<div className="query_list">
				<Flicking
					ref={(carousel) => {
						this.carousel = carousel;
					}}
					tag = "div"
                    viewportTag = "div"
                    cameraTag = "div"
                    classPrefix = "eg-flick"
                    deceleration = {0.0075}
                    horizontal = {true}
                    circular = {false}
                    infinite = {false}
                    infiniteThreshold = {0}
                    lastIndex = {Infinity}
                    threshold = {20}
                    duration = {500}
                    panelEffect = {x => 1 - Math.pow(1 - x, 3)}
                    defaultIndex = {0}
                    inputType = {["touch", "mouse"]}
                    thresholdAngle = {45}
                    bounce = {['10', '10']}
                    autoResize = {true}
                    adaptive = {false}
                    bound = {false}
                    overflow = {true}
                    hanger = {0}
                    anchor = {0}
                    gap = {5}
                    zIndex = {8}
					align = "prev"
					moveType = "snap"
                    //moveType = {{type: "snap", count: 1}}
                    collectStatistics = {false}>
					{quickReplies}
				</Flicking>
			</div>
		);
	}
}
function mapStateToProps(state) {
	return { ...state };
}

function mapDispatchToProps(dispatch) {
	return { send: (payload) => dispatch(messageActions.sendMessage(payload)) };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickReplies);
