import axios from 'axios';
import { APP_CONFIG } from './config'
/*
    Returns a Axios Request Promise
*/
function request(url, method = 'post', data, config) {

    return axios({
        method,
        url,
        data,
        ...config
    }).then(
        response => {
            return {response};
        }
    ).catch(
        error => {
            return {error};
        }
    );
}

function chatbotUrl(isDev) {

    let returnUrl = APP_CONFIG.chatbotUrl
    if (isDev === true) {
        returnUrl += '_develop'
    } 
    return returnUrl
}

export {request, chatbotUrl}
