import React, { PureComponent } from 'react';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';
import { connect } from 'react-redux';
import * as messageActions from '../modules/message';
import * as uiActions from '../modules/ui';
import * as arkActions from '../modules/ark';
import * as settingAction from '../modules/setting';
import * as feedbackAction from '../modules/feedback';
import * as configAction from '../modules/config';
import { ChatTop, ChatBottom, ChatMessageList } from '../Components';
import {
	SettingLayer,
	SideMenuLayer,
	AlertLayer,
	LoginAlertLayer,
	OverLayLayer,
	FeedbackLayer,
	AgreeContentLayer,
	AlertFavorites,
	UseTip,
} from '../Components/Layer';
import { LANG_CN, LANG_KO, LANG_EN } from '../utils/lang';

import '../css/chatbot.css';
import '@egjs/react-flicking/dist/flicking.css';
// Or, if you have to support IE9
import '@egjs/react-flicking/dist/flicking-inline.css';
//import sc from 'styled-components';

const urlPropsQueryConfig = {
	isDev: { type: UrlQueryParamTypes.boolean, queryParam: 'isDev' },
	projectId: { type: UrlQueryParamTypes.String, queryParam: 'projectId' },
	lang: { type: UrlQueryParamTypes.String, queryParam: 'lang' },
};
class App extends PureComponent {
	constructor(props) {
		super(props);

		let isIframe = false;
		// iframe시 parent에게 보내줄 초기 Data
		if (window && window.parent && window !== window.parent) {
			const LAYER_LOAD_INIT = {
				enabled: true,
				publicInfo: {
					icon: '',
					title: '나래',
					iconSize: '68px',
					themeColor: '#2f6e8d',
					description: '[]',
					iconPosition: 'right',
					popupMessage: '<HTML> <body> <p style="color:#2F6E8D;"><b>\n도움이 필요하신가요?</b></body> </HTML>',
					showPopupMessage: false,
					buttonBorderRadius: '50%',
				},
			};
			window.parent.postMessage(LAYER_LOAD_INIT, '*');
			isIframe = true;
		}
		// config 세팅
		if (this.props.projectId !== undefined && this.props.projectId !== null && this.props.projectId !== '') {
			this.props.configEvents.setProject(this.props.projectId);
		}

		
		if (this.props.lang !== undefined && this.props.lang !== null && this.props.lang !== '') {
			this.props.configEvents.setLang(this.props.lang);
			let textData = LANG_KO;
			if (this.props.lang === 'en') textData = LANG_EN;
			if (this.props.lang === 'cn') textData = LANG_CN;

			this.props.uiEvents.Layout.setTexts(textData);
		}
		

		if (this.props.isDev !== undefined && this.props.isDev !== null && this.props.isDev !== '') {
			this.props.configEvents.setIsDev(this.props.isDev);
		}

		/*
		if (this.props.useTip !== undefined && this.props.useTip !== null && this.props.useTip !== '') {
			//this.props.configEvents.setIsDev(this.props.isDev);
		}
		*/

		if (!isIframe) {
			this.botInit();
		} else {
			window.addEventListener('message', function (e) {
				if (e.data.botInit === true) {
					window.botInit();
				}
			});
		}

		this.state = {
			alertMsg: '',
			closeAll: false,
			showUseTip: false,
			showExpiresBtn: true,
		};

		const HAS_VISITED_BEFORE = localStorage.getItem('hasVisitedBefore');
		if (HAS_VISITED_BEFORE && Date.parse(HAS_VISITED_BEFORE) > new Date()) {
			this.state = {
				...this.state,
				showUseTip: false,
			};
			//console.info("hideUseTip", this.state)
		} else {
			this.state = {
				...this.state,
				showUseTip: true,
			};
			//console.info("showUseTip", this.state)
		}

		// 이벤트 바인딩
		this.handleSetSetting = this.handleSetSetting.bind(this);
		this.handleGetGreeting = this.handleGetGreeting.bind(this);
		//this.handleCloseUseTip = this.handleCloseUseTip(this);
		//this.handleSetExpires = this.handleSetExpires(this);
		//this.handleSetLanguage = this.handleSetLanguage.bind(this);
		window.botInit = this.botInit.bind(this);
	}

	pushSet() {
		//console.info(this.props.setting)
		return setTimeout(() => {
			//alert('Push message');
			let sendData = {
				sessionKey: this.props.message.sessionKey,
				channelId: this.props.message.channelId,
				isLogin: this.props.message.isLogin,
				isDev: this.props.config.isDev,
				projectId: this.props.config.projectId,
			};
			this.props.messageEvents.getPush(sendData);
		}, this.props.setting.pushTimeout * 1000 * 60);
	}

	timer;
	componentDidUpdate(prevProps, prevState) {
		//console.info("this sessionKey======>",this.props.message.sessionKey)
		//console.info("prev sessionKey======>", prevProps.message.sessionKey)
		if (this.props.setting.pushTimeout !== undefined && this.props.setting.pushTimeout !== '' && this.props.setting.pushTimeout > 0) {
			if (prevProps.message.isPushMessage === undefined && this.props.message.isPushMessage === false) {
				//처음
				this.timer = this.pushSet();
			} else if (prevProps.message.isPushMessage === false && this.props.message.isPushMessage === false) {
				if (this.timer !== undefined) {
					clearTimeout(this.timer);
				}
				this.timer = this.pushSet();
			} else {
				if (this.timer !== undefined) {
					clearTimeout(this.timer);
				}
			}
		}

		if (this.props.feedback.alertMessage !== undefined && this.props.feedback.alertMessage !== '') {
			this.openAlert(this.props.feedback.alertMessage);
		}
		// 자동완성 OFF이고 이전에 자동완성 데이터가 있는 경우 초기화 한다.
		if (prevProps.setting.useArk !== this.props.setting.useArk && prevProps.setting.useArk === true && this.props.setting.useArk === false) {
			this.props.arkEvents.init();
		}
		// 자동완성 ON이고 질의문 입력창에 질의가 있는 경우 자동완성데이터를 요청한다.
		if (
			prevProps.setting.useArk !== this.props.setting.useArk &&
			prevProps.setting.useArk === false &&
			this.props.setting.useArk === true &&
			this.props.ark.query
		) {
			this.props.arkEvents.get({ value: this.props.ark.query, isDev: this.props.isDev });
		}

		/*
        let key = this.props.projectId
        if (key === undefined) key = '13'

        if (prevProps.message !== this.props.message) {
            if (prevProps.message.data.length === 0 && this.props.message.data.length === 1) {
                const _data = JSON.parse(window.sessionStorage.getItem('_data_'+key))
                if (_data !== undefined && _data !== null && _data.data.length > 0 && _data.isLogin === this.props.setting.isLogin) {
                    this.props.messageEvents.init({initData: _data.data, sessionKey: this.props.message.sessionKey}) 
                } else if (_data !== undefined && _data !== null && _data.isLogin !== this.props.setting.isLogin) {
                    window.sessionStorage.clear();
                }
            }

            if (this.props.message.sessionKey !== null && this.props.message.sessionKey !== "") {
                window.sessionStorage.setItem('_data_'+key, JSON.stringify(this.props.message))
            }
            // 다시 그릴때 실시간을 무조건 보이게 세팅
            //this.props.uiEvents.Layout.toggleRealtimeAnswer(true)
        } 
        */

		/*
		if (prevProps.setting.favorite !== this.props.setting.favorite) {
			this.handleSetSetting();
		}
		*/
	}

	botInit() {
		this.props.settingEvents.get(this.props);
		//this.props.realTimeAnswerEvent.get(this.props);
		this.props.messageEvents.get(this.props);
	}

	openAlert(alertMsg) {
		this.setState({
			alertMsg: alertMsg,
		});
		this.props.uiEvents.Layout.controlOverlay({ toggle: true, closeAll: this.state.closeAll });
		if (this.props.feedback.alertMessage !== '') {
			this.setState({
				closeAll: true,
			});
			this.props.feedbackEvents.clearAlert();
		}
	}

	closeAlert() {
		this.setState({
			alertMsg: '',
		});
		this.props.uiEvents.Layout.controlOverlay({ toggle: false, closeAll: this.state.closeAll });
		if (this.state.closeAll === true) {
			this.setState({
				closeAll: false,
			});
		}
	}

	setExpires() {
		let now = new Date();
		let expires = new Date(now.setMonth(now.getMonth() + 1));
		localStorage.setItem('hasVisitedBefore', expires);
		this.setState({
			showUseTip: false,
		});
	}

	closeUseTip() {
		this.setState({
			showUseTip: false,
		});
	}

	openUseTip() {
		this.setState({
			showUseTip: true,
			showExpiresBtn: false,
		});
	}

	handleSetSetting() {
		if (this.props.message.isLogin) {
			this.props.settingEvents.set({ settingData: this.props.setting, isDev: this.props.config.isDev, projectId: this.props.config.projectId });
		}
	}

	handleGetGreeting() {
		//console.info(this.props)
		this.props.messageEvents.get(this.props);
	}

	/*
	handleSetLanguage(lang) {
		this.props.configEvents.setLang(lang);
		switch (lang) {
			case 'ko':
				this.props.configEvents.setProject('14');
				break;
			case 'en':
				//this.props.configEvents.setProject('14');
				break;
			case 'cn':
				//this.props.configEvents.setProject('15');
				break;
			default:
		}

		//this.props.settingEvents.get(this.props);
		//this.props.realTimeAnswerEvent.get(this.props);
		//this.props.messageEvents.get(this.props);
	}
	*/
	render() {
		let mainClass = 'chat_wrap';

		return (
			<React.Fragment>
				{this.state.showUseTip && (
					<UseTip closeUseTip={() => this.closeUseTip()} setExpires={() => this.setExpires()} showExpiresBtn={this.state.showExpiresBtn} uiTexts={this.props.ui.texts} lang={this.props.lang}/>
				)}

				<div className={mainClass}>
					<ChatTop
						config={this.props.config}
						setLang={this.handleSetLanguage}
						lang={this.props.config.language}
						openSideMenu={this.props.uiEvents.Layout.openSideMenu}
						openFeedback={this.props.uiEvents.Layout.openFeedback}
						loginAlert={this.props.uiEvents.Layout.openLoginAlert}
						controlOverlay={this.props.uiEvents.Layout.controlOverlay}
						isLogin={this.props.message.isLogin}
						init={this.props.message.init}
						menuPosition={this.props.setting.menuPosition}
						uiTexts={this.props.ui.texts}
						theme={this.props.setting.theme}
					/>
					<ChatMessageList
						data={this.props.message}
						isDev={this.props.config.isDev}
						favorite={this.props.setting.favorite}
						uiTexts={this.props.ui.texts}
						reload={this.handleGetGreeting}
						ref={(ref) => {
							this.ChatMessageList = ref;
						}}
					/>
					<ChatBottom
						config={this.props.config}
						realtimeAnswer={this.props.realtimeAnswer}
						getArk={this.props.arkEvents.get}
						useArk={this.props.setting.useArk}
						settingArk={this.props.settingEvents.useArk}
						arkData={this.props.ark.data}
						showFavorites={this.props.ui.showFavorites}
						showRealtimeAnswer={this.props.ui.showRealtimeAnswer}
						toggleFavorites={this.props.uiEvents.Layout.toggleFavorites}
						setFavorite={this.props.settingEvents.setFavorite}
						favorite={this.props.setting.favorite}
						sessionKey={this.props.message.sessionKey}
						channelId={this.props.message.channelId}
						responseType={this.props.message.responseType}
						blockId={this.props.message.dialogTaskId}
						isLogin={this.props.message.isLogin}
						clearTextbox={this.props.message.clearTextbox}
						send={this.props.messageEvents.send}
						uiTexts={this.props.ui.texts}
						ref={(ref) => {
							this.ChatBottom = ref;
						}}
					/>
				</div>
				{this.props.ui.overLayShow && (
					<OverLayLayer
						click={this.props.uiEvents.Layout.clickOverlay}
						isLoginAlert={this.props.ui.clickLoginAlert}
						isViewAgree={this.props.ui.clickAgreeContent}
						zIndex={this.props.ui.overLayzIndex}
					/>
				)}
				{this.props.ui.clickSideMenu && (
					<SideMenuLayer
						config={this.props.config}
						close={this.props.uiEvents.Layout.closeSideMenu}
						openSetting={this.props.uiEvents.Layout.openSetting}
						loginAlert={this.props.uiEvents.Layout.openLoginAlert}
						send={this.props.messageEvents.send}
						sessionKey={this.props.message.sessionKey}
						channelId={this.props.message.channelId}
						isLogin={this.props.message.isLogin}
						isLoding={this.props.message.isLoding}
						userName={this.props.message.userName}
						menuPosition={this.props.setting.menuPosition}
						theme={this.props.setting.theme}
						setMenuPostion={this.props.settingEvents.menuPosition}
						setTheme={this.props.settingEvents.setTheme}
						setSetting={this.handleSetSetting}
						uiTexts={this.props.ui.texts}
						lang={this.props.config.language}
						quickMenu={this.props.setting.quickMenu}
						openUseTip={() => this.openUseTip()}
					/>
				)}
				{this.props.ui.clickSetting && (
					<SettingLayer
						config={this.props.config}
						close={this.props.uiEvents.Layout.closeSetting}
						alert={(payload) => this.openAlert(payload)}
						useArk={this.props.settingEvents.useArk}
						menuPosition={this.props.settingEvents.menuPosition}
						toggleGreeting={this.props.settingEvents.toggleGreeting}
						toggleQuickMenu={this.props.settingEvents.toggleQuickMenu}
						data={this.props.setting}
						setSetting={this.handleSetSetting}
						uiTexts={this.props.ui.texts}
						lang={this.props.config.language}
					/>
				)}
				{this.props.ui.clickFeedback && (
					<FeedbackLayer
						config={this.props.config}
						view={this.props.uiEvents.Layout.openAgreeContent}
						close={this.props.uiEvents.Layout.closeFeedback}
						alert={(payload) => this.openAlert(payload)} // 안에서 밖으로 보내는 에러 메세지
						write={this.props.feedbackEvents.write}
						uiTexts={this.props.ui.texts}
					/>
				)}
				{this.props.ui.clickAgreeContent && <AgreeContentLayer close={this.props.uiEvents.Layout.closeAgreeContent} />}
				{this.props.ui.clickLoginAlert && (
					<LoginAlertLayer
						clickOverlay={this.props.uiEvents.Layout.clickOverlay}
						close={this.props.uiEvents.Layout.closeLoginAlert}
						clickSideMenu={this.props.ui.clickSideMenu}
						uiTexts={this.props.ui.texts}
					/>
				)}
				{this.props.ui.showFavoriteAlert && (
					<AlertFavorites
						action={this.props.ui.favoriteAction}
						utterance={this.props.ui.tempUtterance}
						favorites={this.props.setting.favorite}
						setFavorite={this.props.settingEvents.setFavorite}
						clickOverlay={this.props.uiEvents.Layout.clickOverlay}
						close={this.props.uiEvents.Layout.closeFavoriteAlert}
						setSetting={this.handleSetSetting}
						uiTexts={this.props.ui.texts}
					/>
				)}
				{this.state.alertMsg !== '' && (
					<AlertLayer close={() => this.closeAlert()} message={this.state.alertMsg} uiTexts={this.props.ui.texts} />
				)}
			</React.Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		ui: state.ui,
		message: state.message,
		ark: state.ark,
		setting: state.setting,
		feedback: state.feedback,
		config: state.config,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		configEvents: {
			setProject: (payload) => dispatch(configAction.setProject(payload)),
			setLang: (payload) => dispatch(configAction.setLang(payload)),
			setIsDev: (payload) => dispatch(configAction.setIsDev(payload)),
		},
		messageEvents: {
			get: (payload) => dispatch(messageActions.getGreeting(payload)),
			send: (payload) => dispatch(messageActions.sendMessage(payload)),
			init: (payload) => dispatch(messageActions.initMessage(payload)),
			getPush: (payload) => dispatch(messageActions.pushMessage(payload)),
		},
		uiEvents: {
			Layout: {
				openSideMenu: () => dispatch(uiActions.openSideMenu()),
				closeSideMenu: () => dispatch(uiActions.closeSideMenu()),
				openSetting: () => dispatch(uiActions.openSetting()),
				closeSetting: () => dispatch(uiActions.closeSetting()),
				openFeedback: () => dispatch(uiActions.openFeedback()),
				closeFeedback: () => dispatch(uiActions.closeFeedback()),
				openAgreeContent: () => dispatch(uiActions.openAgreeContent()),
				closeAgreeContent: () => dispatch(uiActions.closeAgreeContent()),
				openLoginAlert: () => dispatch(uiActions.openLoginAlert()),
				closeLoginAlert: () => dispatch(uiActions.closeLoginAlert()),
				clickOverlay: () => dispatch(uiActions.clickOverlay()),
				controlOverlay: (payload) => dispatch(uiActions.controlOverlay(payload)),
				toggleFavorites: () => dispatch(uiActions.toggleFavorites()),
				closeFavoriteAlert: () => dispatch(uiActions.closeFavoriteAlert()),
				setTexts: (payload) => dispatch(uiActions.setTexts(payload)),
				toggleRealtimeAnswer: (payload) => dispatch(uiActions.showRealtimeAnswer(payload)),
				toggleUseTip: (payload) => dispatch(uiActions.toggleUseTip(payload)),
			},
		},
		arkEvents: {
			get: (payload) => dispatch(arkActions.getArk(payload)),
			init: () => dispatch(arkActions.initArk()),
		},
		settingEvents: {
			get: (payload) => dispatch(settingAction.getSetting(payload)),
			set: (payload) => dispatch(settingAction.setSetting(payload)),
			useArk: (payload) => dispatch(settingAction.useArk(payload)),
			menuPosition: (payload) => dispatch(settingAction.menuPosition(payload)),
			toggleGreeting: (payload) => dispatch(settingAction.toggleGreeting(payload)),
			toggleQuickMenu: (payload) => dispatch(settingAction.toggleQuickMenu(payload)),
			setFavorite: (payload) => dispatch(settingAction.setFavorite(payload)),
			setTheme: (payload) => dispatch(settingAction.setTheme(payload)),
		},
		feedbackEvents: {
			write: (payload) => dispatch(feedbackAction.writeFeedback(payload)),
			clearAlert: () => dispatch(feedbackAction.clearFeedbackAlert()),
		},
	};
}

export default addUrlProps({ urlPropsQueryConfig })(connect(mapStateToProps, mapDispatchToProps)(App));
