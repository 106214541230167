import React, { PureComponent } from 'react';
import { BasicCard, SimpleText } from './AnswerBalloon';

import Flicking from '@egjs/react-flicking';

import $ from 'jquery';

class CarouselBalloon extends PureComponent {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		if (this.carousel !== undefined) {
			let panelCount = this.carousel.panelCount;
			let maxHeight = 0;
			for (let idx = 0; idx < panelCount; idx++) {
				if (maxHeight < $(this.carousel.getPanel(idx).element.firstChild.firstChild).height()) {
					maxHeight = $(this.carousel.getPanel(idx).element.firstChild.firstChild).height();
				}
			}

			if (maxHeight > 0) {
				for (let idx = 0; idx < panelCount; idx++) {
					this.carousel.getPanel(idx).element.firstChild.firstChild.style.height = maxHeight + 'px';
				}
			}
		}
	}

	render() {
		const itemType = this.props.data.type;
		const carouselItems = this.props.data.items.map((item, i) =>
			itemType === 'simpleText' ? (
				<div key={i}>
					<SimpleText data={item} isCarousel={false} />
				</div>
			) : (
				itemType === 'basicCard' && (
					<div key={i}>
						<BasicCard data={item} isCarousel={false} />
					</div>
				)
			),
		);

		return this.props.data.items.length > 1 ? (
			<div className="menual_area">
				<Flicking
					deceleration={0.0075}
					horizontal={true}
					circular={false}
					threshold={20}
					duration={500}
					defaultIndex={0}
					inputType={['touch', 'mouse']}
					bounce={['10', '10']}
					autoResize={true}
					adaptive={false}
					bound={false}
					hanger={0}
					anchor={0}
					align="prev"
					ref={(carousel) => {
						this.carousel = carousel;
					}}
				>
					{carouselItems}
				</Flicking>
			</div>
		) : this.props.data.type === 'simpleText' ? (
			<SimpleText data={this.props.data.items[0]} isCarousel={this.props.isCarousel} />
		) : (
			this.props.data.type === 'basicCard' && <BasicCard data={this.props.data.items[0].itemType} isCarousel={this.props.isCarousel} />
		);
	}
}

export default CarouselBalloon;
