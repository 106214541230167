import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as messageActions from '../../../modules/message';
import { nl2br, nl2brElement } from '../../../utils/utils';
import { APP_CONFIG } from '../../../utils/config';

import Flicking from '@egjs/react-flicking';
/*
import '@egjs/react-flicking/dist/flicking.css';
// Or, if you have to support IE9
import '@egjs/react-flicking/dist/flicking-inline.css';
*/
//import sc from 'styled-components';
class SimpleGrid extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			currentPage: 0,
		};
	}

	clickMessage(value) {
		let sendData = {
			utterance: value,
			sessionKey: this.props.message.sessionKey,
			channelId: this.props.message.channelId,
			isLogin: this.props.message.isLogin,
			isDev: this.props.config.isDev,
			blockId: '',
			projectId: this.props.config.projectId,
		};
		//console.log(sendData);
		this.props.send(sendData);
	}

	clickPhoneNum(phoneNum) {
		document.location.href = 'tel:' + phoneNum;
	}

	clickProject(projectId) {
		const param = {
			projectId: projectId,
		};
		this.props.get(param);
	}

	clickPageDot(pIndex) {
		this.carousel.moveTo(pIndex, 500);
		this.changePageDot(pIndex);
		//this.moveEnd(this.changePageDot());
	}

	changePageDot(pIndex) {
		//console.debug('before', this.carousel.index);
		this.setState({
			currentPage: pIndex,
		});
		//console.debug('after', this.carousel.index);
	}

	render() {
		const getButton = (data, idx) => {
			if (data.action === 'webLink') {
				if (data.target) {
					return (
						<a href={data.webLinkUrl} target={data.target} className="icon_box" tabIndex={idx} key={idx}>
							<span className="icon" style={{ backgroundImage: 'url(' + data.image.url + ')' }}></span>
							<span className="m_txt">{nl2brElement(data.label)}</span>
						</a>
					);
				} else {
					return (
						<a href={data.webLinkUrl} target={APP_CONFIG.popWinTarget} className="icon_box" tabIndex={idx} key={idx}>
							<span className="icon" style={{ backgroundImage: 'url(' + data.image.url + ')' }}></span>
							<span className="m_txt">{nl2brElement(data.label)}</span>
						</a>
					);
				}
			}
			if (data.action === 'message') {
				return (
					<a href="#none" className="icon_box" tabIndex={idx} onClick={() => this.clickMessage(data.messageText)} key={idx}>
						<span className="icon" style={{ backgroundImage: 'url(' + data.image.url + ')' }}></span>
						<span className="m_txt">{nl2brElement(data.label)}</span>
					</a>
				);
			}
			if (data.action === 'phone') {
				return (
					<a href="#none" className="icon_box" tabIndex={idx} onClick={() => this.clickPhoneNum(data.phoneNumber)} key={idx}>
						<span className="icon" style={{ backgroundImage: 'url(' + data.image.url + ')' }}></span>
						<span className="m_txt">{nl2brElement(data.label)}</span>
					</a>
				);
			}
			if (data.action === 'project') {
				return (
					<a href="#none" className="icon_box" tabIndex={idx} onClick={() => this.clickProject(data.id)} key={idx}>
						<span className="icon" style={{ backgroundImage: 'url(' + data.image.url + ')' }}></span>
						<span className="m_txt">{nl2brElement(data.label)}</span>
					</a>
				);
			}
		};

		const getItems = (startIdx, endIdx) => {
			let ret = this.props.data.buttons.map((button, idx) => idx >= startIdx && idx < endIdx && <li key={idx}>{getButton(button, idx)}</li>);
			return ret;
		};

		const setRows = this.props.data.buttonRow !== undefined ? this.props.data.buttonRow : 2;
		const setColumns = 3;
		const pagePerButtons = setColumns * setRows;
		const buttonCnt = this.props.data.buttons.length;
		const totalPages = Math.ceil(buttonCnt / setColumns / setRows);

		const getGrids = () => {
			let startIdx = 0;
			let endIdx = 0;
			let ret = [];
			for (let i = 0; i < totalPages; i++) {
				startIdx = i * pagePerButtons;
				endIdx = pagePerButtons * (i + 1);
				ret[i] = (
					<div className="chat_menu" style={{ width: '100%', display: 'inline-block' }} key={i}>
						{getItems(startIdx, endIdx)}
					</div>
				);
			}
			return ret;
		};

		let pagination = () => {
			let ret = [];

			for (let i = 0; i < totalPages; i++) {
				if (this.state.currentPage === i) {
					ret[i] = <li className="checked" key={i}></li>;
				} else {
					ret[i] = <li onClick={() => this.clickPageDot(i)} key={i}></li>;
				}
			}
			return ret;
		};
		let mainImageWidth;
		let mainImageHeight;

		let inStyle;
		
		if(this.props.data.image){
			mainImageWidth =  this.props.data.image.width === "" ? "130px" : this.props.data.image.width;
			mainImageHeight = this.props.data.image.height === "" ? "130px" : this.props.data.image.height;
			inStyle = {
				content:"",
				display:"block",
				width: mainImageWidth,
				height: mainImageHeight,
				background:"url("+this.props.data.image.url+") 0 0 no-repeat",
				backgroundSize:"contain",
				margin: "0 auto 5px auto"
			}
		}

		return (
			<div className="bot_box">
				<div className="bot_txt">
					{this.props.data.image !== undefined && this.props.data.image.url !== "" &&
					<div style={inStyle}/>
					}
					<div className="menu_box" dangerouslySetInnerHTML={{ __html: nl2br(this.props.data.text) }} />
					<br />

					<Flicking
						ref={(carousel) => {
							this.carousel = carousel;
						}}
						deceleration={0.0075}
						horizontal={true}
						circular={false}
						threshold={20}
						duration={500}
						defaultIndex={0}
						inputType={['touch', 'mouse']}
						moveType="snap"
						autoResize={true}
						adaptive={false}
						bound={false}
						hanger={0}
						anchor={0}
						onChanged={() => this.changePageDot(this.carousel.index)}
					>
						{getGrids()}
					</Flicking>
					<div className="pagination">
						<ul>{pagination()}</ul>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return { ...state };
}

function mapDispatchToProps(dispatch) {
	return {
		send: (payload) => dispatch(messageActions.sendMessage(payload)),
		get: (payload) => dispatch(messageActions.getGreeting(payload)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleGrid);
