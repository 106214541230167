import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import HttpsRedirect from 'react-https-redirect';

import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { App } from './containers';
import * as serviceWorker from './serviceWorker';

import rootReducer from './modules';
import rootSaga from './sagas';

import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

const sagaMiddleware = createSagaMiddleware();
let middleware = [sagaMiddleware];
// DO NOT USE REDUX-LOGGER IN PRODUCTION ENV
if (process.env.NODE_ENV !== 'production') {
	const logger = createLogger();
	middleware = [...middleware, logger];
}

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));
sagaMiddleware.run(rootSaga);

if (process.env.NODE_ENV !== 'production') {
    ReactDOM.render(
        <Provider store={store}>
            <App/>
        </Provider>
    , document.getElementById('root'));
} else {
	/*
    ReactDOM.render(
        <HttpsRedirect>
            <Provider store={store}>
                <App/>
            </Provider>
        </HttpsRedirect>
    , document.getElementById('root'));
	*/
   
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('root'),
    );
    
}
serviceWorker.unregister();
