import { call, put, fork, takeLatest } from 'redux-saga/effects'
import * as ActionTypes from '../modules/ActionTypes';
import {getSetting, setSetting} from '../services/setting';
import { DEFAULT_SETTING } from '../utils/config'

function* callGetSetting(action) {
    const { response, error } =  yield call(getSetting, action.payload);

    if (response) {
        yield put({type: ActionTypes.GET_SETTING.SUCCESS, payload: { response }});
    } else {
        console.error('[ERROR][getSetting]',error)
        const response = DEFAULT_SETTING;
        yield put({type: ActionTypes.GET_SETTING.SUCCESS, payload: { response }});
    }
}

function* callSetSetting(action) {
    const { response, error } =  yield call(setSetting, action.payload);

    if (response) {
        yield put({type: ActionTypes.SET_SETTING.SUCCESS, payload: { response }});
    } else {
        console.error('[ERROR][setSetting]',error)
    }
}

function* watchGet() {
    yield takeLatest(ActionTypes.GET_SETTING.REQUEST, callGetSetting);
    yield takeLatest(ActionTypes.SET_SETTING.REQUEST, callSetSetting);
}

export default function* settingSaga() {
    yield fork(watchGet);
}