import { createAction } from 'redux-actions';
import * as ActionTypes from './ActionTypes';

export const getArk = createAction(ActionTypes.GET_ARK.REQUEST);
export const initArk = createAction(ActionTypes.INIT_ARK)

const initialState = {}

function ark(state = initialState, action) {
    //console.info('[reducers.message.message][action]', action)
    switch(action.type) {
        case ActionTypes.GET_ARK.REQUEST: 
            //console.log(action.payload)
            return {
                ...state,
                query : action.payload.value
            }
        case ActionTypes.GET_ARK.SUCCESS:  // 자동완성을 성공적으로 받았을 경우
            return {
                ...state,
                data: action.payload.response.data,
            }
        case ActionTypes.SEND_MESSAGE.REQUEST:  // 메세지을 보낼 때 state 변경
            // console.info(action.type)
            return {
                data: null,
                query: state.query
            };
        case ActionTypes.INIT_ARK:
            //console.log(state.query)
            return {
                data: null,
                query: state.query
            }
        default:
            return state;
    }
}

export default ark;