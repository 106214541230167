let APP_CONFIG;

if (process.env.NODE_ENV === 'production') {
	// real server config
	APP_CONFIG = {
		channelCode: 'DEFAULT',
		chatbotUrl: 'https://chat.hywoman.ac.kr',
		getGreeting: '/message/greeting',
		sendQuestion: '/message/send',
		sendTrigger: '/message/trigger',
		sendPostback: '/message/postback',
		getPush: '/message/getPush',
		getArk: '/ark',
		getRealtimeAnswer: '/realTimeAnswer',
		writeFeedback: '/feedback/write',
		logIn: 'https://chat.hywoman.ac.kr/exsignon/sso/sso_idp_login.jsp?RelayState=',
		logOut: 'https://chat.hywoman.ac.kr/exsignon/sso/logout.jsp?RelayState=',
		getSetting: '/setting/get',
		setSetting: '/setting/set',
		popWinTarget: '_blank',
		maxSetting: 5,
		privacyLink: '',
		useLogin: false,
		timeout: 30000,
	 };

} else {
	APP_CONFIG = {
		channelCode: 'DEFAULT',
		chatbotUrl: 'http://211.39.140.249:17400/ichat_api',
		//chatbotUrl: 'http://localhost:8080/ichat_api',
		getGreeting: '/message/greeting',
		sendQuestion: '/message/send',
		sendTrigger: '/message/trigger',
		sendPostback: '/message/postback',
		getPush: '/message/getPush',
		getArk: '/ark',
		getRealtimeAnswer: '/realTimeAnswer',
		writeFeedback: '/feedback/write',
		logIn: 'http://ssodev.ewha.ac.kr/SSO_IDP/swift/sso/loginForm.jsp?RSP=chatdev.ewha.ac.kr_ichat_api_dev&RelayState=%2Findex.jsp',
		logOut: 'http://chatdev.ewha.ac.kr:8080/ichat_api_dev/swift/sso/requestStdLogout.jsp',
		getSetting: '/setting/get',
		setSetting: '/setting/set',
		popWinTarget: '_blank',
		maxSetting: 5,
		privacyLink: '',
		useLogin: false,
		timeout: 30000,
	};
}

const NEED_LOGIN_TEMPLATE = {
	outputs: [
		{
			simpleText: {
				text: '나래를 이용하려면 로그인이 필요해요. 😃<br/><br/>로그인 후 다시 질문 해 줄래요?',
				buttons: [
					{
						label: '로그인 하기',
						action: 'webLink',
						webLinkUrl: '',
						target: '_self',
					},
				],
			},
		},
	],
	quickReplies: null,
};

const DEFAULT_GREETING_TEMPLATE = {
	outputs: [
		{
			simpleText: {
				text: '안녕하세요 나래 입니다. 🙂',
			},
		},
	],
	quickReplies: null,
};

const DEFAULT_SETTING = {
	data: {
		isLogin: false,
		useArk: true,
		menuPosition: 'right',
		greeting: [],
		quickMenu: [
			{
				isUse: '1',
				id: 21.0,
				label: '학사일정',
				utterance: '학사일정 알려주세요.',
			},
			{
				isUse: '1',
				id: 23.0,
				label: '맵/오시는길',
				utterance: '한양여자대학교 가는 방법 안내해주세요.',
			},
			{
				isUse: '0',
				id: 24.0,
				label: '복지/편의시설',
				utterance: '복지/편의시설 안내해주세요.',
			},
			{
				isUse: '0',
				id: 25.0,
				label: '부서별 전화번호',
				utterance: '부서별 전화번호 안내해주세요.',
			},
			{
				isUse: '1',
				id: 48.0,
				label: '공지사항',
				utterance: '공지사항 안내해주세요.',
			},
			{
				isUse: '1',
				id: 49.0,
				label: '대학소식',
				utterance: '대학소식 안내해주세요.',
			},			
		],
		theme: '',
		state: {
			code: 0,
			detail: 'SUCCESS',
		},
	},
};

const DEFAULT_ERROR = {
	outputs: [
		{
			simpleText: {
				text: '앗, 챗봇 서비스 점검 중이네요😢 잠시 후에 다시 질문해줄래요?',
			},
		},
	],
	quickReplies: null,
};

const DEFAULT_ERROR_EN = {
	outputs: [
		{
			simpleText: {
				text: 'The chatbot service is undergoing maintenance😢 Please try again later.',
			},
		},
	],
	quickReplies: null,
};

const DEFAULT_ERROR_CN = {
	outputs: [
		{
			simpleText: {
				text: 'chatbot服务正在维护中。😢 请稍后再试一下。',
			},
		},
	],
	quickReplies: null,
};

export { APP_CONFIG, NEED_LOGIN_TEMPLATE, DEFAULT_GREETING_TEMPLATE, DEFAULT_SETTING, DEFAULT_ERROR, DEFAULT_ERROR_EN, DEFAULT_ERROR_CN };
