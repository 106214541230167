import React, { Component } from 'react';

class RealtimeAnswer extends Component {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data !== prevProps.data) {
			//if (this.carousel !== null) this.carousel.resize();
			/*
            if (this.state.show) {
                this.div_realtime.style.display = "block";
                this.div_realtime_button.className = "btn_qna is_active"
            } else {
                this.div_realtime.style.display = "none";
                this.div_realtime_button.className = "btn_qna"
            }
            */
		}
	}

	send(value) {
		let sendData = {
			utterance: value,
			sessionKey: this.props.sessionKey,
			channelId: this.props.channelId,
			isLogin: this.props.isLogin,
			isDev: this.props.config.isDev,
			projectId: this.props.config.projectId,
		};

		this.props.send(sendData);
		this.div_realtime.style.display = 'none';
		this.div_realtime_button.className = 'btn_qna';
	}

	showHide() {
		if (this.div_realtime.style.display === 'none') {
			this.div_realtime.style.display = 'block';
			this.div_realtime_button.className = 'btn_qna is_active';
			this.setState = {
				show: false,
			};
		} else if (this.div_realtime.style.display === 'block') {
			this.div_realtime.style.display = 'none';
			this.div_realtime_button.className = 'btn_qna';
			this.setState = {
				show: true,
			};
		}
	}

	render() {
		const displayStyle = {
			display: 'none',
		};

		let maxRows = 5;
		if (this.props.fromAdvisor) maxRows = 3;
		return (
			<div className="ly_box_bottom">
				<button
					type="button"
					className="btn_qna"
					onClick={() => {
						this.showHide();
					}}
					ref={(ref) => {
						this.div_realtime_button = ref;
					}}
				>
					{this.props.uiTexts.text_07}
					<span className="date_num">({this.props.data.time})</span>
					<span className="ico_comm ico_qna">보기/접기</span>
				</button>
				<div
					className="qna_box"
					style={displayStyle}
					ref={(ref) => {
						this.div_realtime = ref;
					}}
				>
					<ul className="qna_list">
						{this.props.data.rank !== undefined &&
							this.props.data.rank !== null &&
							this.props.data.rank.map(
								(item, index) =>
									index < maxRows && (
										<li className="qna_item" key={index}>
											<a href="#none" onClick={() => this.send(item)} className="qna_link" key={index}>
												{index + 1}.{item}
											</a>
										</li>
									),
							)}
					</ul>
				</div>
			</div>
		);
	}
}

/*

            <div className="ly_box_bottom is_open">
                <div className="ly_box_top">
                    <strong className="ly_box_tit">자동완성 기능</strong>
                    { this.props.useArk 
                        ? <button type="button" className="btn_control is_active" onClick={()=>{{this.settingArk(false)}}}>ON</button>
                        : <button type="button" className="btn_control"  onClick={()=>{{this.settingArk(true)}}}>OFF</button>
                    }
                </div>
                <div className="auto_box" style={displayBlock}>
                    <ul className="qna_list">
                    { this.props.data !== undefined && this.props.data !== null &&
                        this.props.data.map(
                            (item, index) => (
                                <li className="qna_item" style={displayBlock} key={index}>
                                    <a href="#none" onClick={() => this.send(item.text)} className="qna_link" style={displayBlock} key={index}>{this.convertHL(item.modified)}</a>
                                </li>
                            )
                        )
                    }
                    </ul>
                </div>
            </div>
*/
export default RealtimeAnswer;
