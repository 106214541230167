import React from 'react';
import { isMobile } from 'react-device-detect';

export function getLink(link) {
	let retLink = link;

	if (link.indexOf('||') > 0) {
		let links = link.split('||');
		if (isMobile) retLink = links[1];
		else retLink = links[0];
	}

	return retLink;
}

export function getTalkTime() {
	const currentNow = new Date();

	let hi = currentNow.getHours();
	let mm = currentNow.getMinutes();
	let ampm = '오전';
	if (hi > 11) {
		ampm = '오후';
		if (hi > 12) {
			hi = hi - 12;
		}
	}

	if (mm < 10) mm = '0' + mm;
	// console.info(mm.length)

	return ampm + ' ' + hi + ':' + mm;
}

export function nl2br(text) {
	//text = '[' + text + ']'
	if (text === undefined || text === null) return text;
	let ret = text.replace(/(?:\r\n|\r|\n)/g, '<br />');
	ret = ret.replace(/(?:\\r\\n|\\r|\\n)/g, '<br />');

	return ret;
}

export function nl2brElement(str) {
	if (!str) return null;

	const lines = str.split('\n');

	if (lines.length === 0) {
		return str;
	}

	return lines.map((line, index) =>
		React.createElement(
			'span',
			{
				key: index,
			},
			line,
			React.createElement('br'),
		),
	);
}

export function openUrl(url, target, isApp) {
	//console.log(isApp)
	/*
	if (url.indexOf('mportal.ewha.ac.kr') > -1 && url.indexOf('mportal.ewha.ac.kr/mportal/main/default') > -1) {
		url = 'https://mportal.ewha.ac.kr/mportal/main/default?redirect=' + url;
	}
	*/

	if (isApp) {
		//url을 검사하자. 일단 URL중 .pdf만 검사
		let type = 'link';
		if (url.indexOf('.pdf') > -1) type = 'file';
		window.openUrl(type, url);
	} else {
		if (target === '_self') {
			document.location.href = url;
		} else {
			const popWin = window.open(url, target);
			popWin.focus();
		}
	}
}
