import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as uiAction from '../modules/ui'

class FavoritesList extends PureComponent {

    setFavorite(item) {
        this.props.controlOverlay({toggle:true, closeAll:false})
        this.props.openFavoriteAlert({utterance : item, action:"del"})
    }

    send(value) {
        let sendData = {
            "utterance" : value, 
            "sessionKey" : this.props.sessionKey,
            "channelId" : this.props.channelId,
            "isLogin" : this.props.isLogin,
            "isDev" : this.props.config.isDev,
            "projectId" : this.props.config.projectId
        }

        this.props.send(sendData);
        this.props.toggleFavorites()
    }
    

    render() {
        let list
        let cnt = 0
        if (this.props.data !== null) {
            list = this.props.data.map(
                (item, i) => (
                    <li className="qna_item" key={i}>
                        <a href="#none" className="qna_link" onClick={()=>{this.send(item)}}>{item}</a>
                        <button type="button" className="btn_delete" onClick={()=>{this.setFavorite(item)}}><span className="ico_comm ico_delete">삭제</span></button>
                    </li>
                )
            )
            cnt = this.props.data.length
        }
      
        return (

            <div id="lyBookmark" className="ly_box_bottom ly_bookmark">
                <strong className="ly_box_tit">{this.props.uiTexts.text_10}({cnt}/5)</strong>
                <div className="qna_box">
                    { cnt === 0 &&
                    <p className="no_list_desc" dangerouslySetInnerHTML={{__html:this.props.uiTexts.text_11}}/>
                    }
                    { cnt >= 0 &&
                        <ul className="qna_list">{list}</ul>
                    }
                </div>
                <button type="button" className="btn_close" onClick={()=>{this.props.toggleFavorites()}}><span className="ico_comm ico_close">즐겨찾는 질문 목록 닫기</span></button>
            </div>
        )
   }
};

function mapStateToProps(state) {
    return {...state};
}

function mapDispatchToProps(dispatch) {
    return {
        openFavoriteAlert: (payload) => dispatch(uiAction.openFavoriteAlert(payload)),
        controlOverlay: (payload) => dispatch(uiAction.controlOverlay(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesList);