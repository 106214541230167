import { createAction } from 'redux-actions';
import * as ActionTypes from './ActionTypes';
//import { LANG_CN, LANG_KO, LANG_EN} from '../utils/lang'
import {LANG_KO} from '../utils/lang'

export const openSideMenu = createAction(ActionTypes.OPEN_SIDEMENU)
export const closeSideMenu = createAction(ActionTypes.CLOSE_SIDEMENU)
export const openSetting = createAction(ActionTypes.OPEN_SETTING)
export const closeSetting = createAction(ActionTypes.CLOSE_SETTING)
export const openFeedback = createAction(ActionTypes.OPEN_FEEDBACK)
export const closeFeedback = createAction(ActionTypes.CLOSE_FEEDBACK)
export const openAgreeContent = createAction(ActionTypes.OPEN_AGREE_CONTENT)
export const closeAgreeContent = createAction(ActionTypes.CLOSE_AGREE_CONTENT)
export const openLoginAlert = createAction(ActionTypes.OPEN_LOGIN_ALERT)
export const closeLoginAlert = createAction(ActionTypes.CLOSE_LOGIN_ALERT)
export const clickOverlay = createAction(ActionTypes.CLICK_OVERLAY)
export const controlOverlay = createAction(ActionTypes.TOGGLE_OVERLAY)

export const toggleFavorites = createAction(ActionTypes.TOGGLE_FAVORITES)
export const openFavoriteAlert = createAction(ActionTypes.OPEN_FAVORITE_ALERT)
export const closeFavoriteAlert = createAction(ActionTypes.CLOSE_FAVORITE_ALERT)
export const setTexts = createAction(ActionTypes.SET_UI_TEXTS)
export const showRealtimeAnswer = createAction(ActionTypes.SHOW_REALTIME_ANSWER)

export const toggleUseTip = createAction(ActionTypes.TOGGLE_USETIP)

const initialState = {
    clickSideMenu : false,
    clickLogin : false,
    clickSetting : false,
    clickFeedback : false,
    clickAgreeContent : false,
    clickLoginAlert : false,
    overLayShow : false,
    overLayzIndex : 100,
    showRealtimeAnswer : true,
    showFavorites : false,
    showFavoriteAlert : false,
    favoriteAction : 'add',
    tempUtterance : '',
    texts : LANG_KO,
    useTip : true,
}

function ui(state = initialState, action) {
    switch(action.type) {
        case ActionTypes.TOGGLE_USETIP:
            return {
                ...state,
                useTip : state.useTip === true ? false : true 
            }
        case ActionTypes.SET_UI_TEXTS: 
        return {
            ...state,
            texts : action.payload
        };
        case ActionTypes.SEND_MESSAGE.REQUEST: 
            return {
                ...state,
                isLoading : true
            };
        case ActionTypes.SEND_MESSAGE.SUCCESS:  // 메세지을 성공적으로 받았을 경우
            return {
                ...state,
                isLoading : false
            };
        case ActionTypes.OPEN_SIDEMENU:
            return {
                ...state,
                clickSideMenu : true,
                overLayShow : true,
                overLayzIndex : 100,
            }
        case ActionTypes.CLOSE_SIDEMENU:
            return {
                ...state,
                clickSideMenu : false,
                overLayShow : false,
            }
        case ActionTypes.OPEN_SETTING:
            return {
                ...state,
                clickSideMenu : false,
                clickSetting : true,
                overLayShow : true,
                overLayzIndex : 100,
            }
        case ActionTypes.CLOSE_SETTING:
            return {
                ...state,
                clickSetting : false,
                overLayShow : false,
            }
        case ActionTypes.OPEN_FEEDBACK:
            return {
                ...state,
                clickFeedback : true,
                overLayShow : true,
                overLayzIndex : 100,
            }
        case ActionTypes.CLOSE_FEEDBACK:
            return {
                ...state,
                clickFeedback : false,
                overLayShow : false,
            }
        case ActionTypes.OPEN_AGREE_CONTENT:
            return {
                ...state,
                clickAgreeContent : true,
                overLayzIndex : 102,
            }
        case ActionTypes.CLOSE_AGREE_CONTENT:
            return {
                ...state,
                clickAgreeContent : false,
                overLayzIndex : 100,
            }
        case ActionTypes.OPEN_LOGIN_ALERT:
            return {
                ...state,
                clickLoginAlert : true,
                overLayzIndex : 102,
            }
        case ActionTypes.CLOSE_LOGIN_ALERT:
            return {
                ...state,
                clickLoginAlert : false,
                overLayzIndex : 100,
            }
        case ActionTypes.CLICK_OVERLAY:
            return {
                ...state,
                overLayShow : false,
                clickLoginAlert : false,
                clickSetting : false,
                clickSideMenu : false,
                clickFeedback : false,
                clickAgreeContent : false,
                overLayzIndex : 100,
                // alertMessage : ''
            }
        case ActionTypes.TOGGLE_OVERLAY:
           let zIndex = 100
           if (action.payload.toggle === true) {
               zIndex = 102
           }
           let ret = {
               ...state,
               overLayShow : true,
               overLayzIndex : zIndex,
           }
           if (action.payload.closeAll === true) {
                ret = {
                    ...state,
                    overLayShow : false,
                    clickLoginAlert : false,
                    clickSetting : false,
                    clickSideMenu : false,
                    clickFeedback : false,
                    clickAgreeContent : false,
                    overLayzIndex : 100,
                }
           }
            return ret
        case ActionTypes.TOGGLE_FAVORITES:
            return {
                ...state,
                showFavorites : state.showFavorites === true ? false : true 
            }
        case ActionTypes.OPEN_FAVORITE_ALERT:
            return {
                ...state,
                showFavoriteAlert : true,
                favoriteAction : action.payload.action,
                tempUtterance : action.payload.utterance,
            }
        case ActionTypes.CLOSE_FAVORITE_ALERT:
            return {
                ...state,
                showFavoriteAlert : false
            }
        case ActionTypes.SHOW_REALTIME_ANSWER:
            // console.debug("[ActionTypes.SHOW_REALTIME_ANSWER]" + action.payload)
            return {
                ...state,
                showRealtimeAnswer : action.payload
            }
        default:
            return state;
    }
}

export default ui;