import React, { PureComponent } from 'react';
import { nl2br } from '../../../utils/utils';
import ButtonItem from './ButtonItem';

class BasicCard extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			thumbnailURL: this.getThumbnail(),
		};
	}

	makeThumbnail() {
		let { thumbnail } = this.props.data;
		let thumbnailUrl = '';
		if (thumbnail !== undefined && thumbnail !== null && thumbnail.imageUrl !== undefined && thumbnail.imageUrl !== null) {
			thumbnailUrl = {
				backgroundImage: "url('" + thumbnail.imageUrl + "')",
				maxWidth: '240px',
			};

			if (thumbnail.fixedRatio === 'true') {
				thumbnailUrl = {
					backgroundImage: "url('" + thumbnail.imageUrl + "')",
					width: thumbnail.width - 11 + 'px',
					height: thumbnail.height - 11 + 'px',
					maxWidth: '240px',
				};
			}
		}

		return thumbnailUrl;
	}

	getThumbnail() {
		let { thumbnail } = this.props.data;
		let thumbnailURL = '';
		if (thumbnail !== undefined && thumbnail !== null && thumbnail.imageUrl !== undefined && thumbnail.imageUrl !== null) {
			thumbnailURL = thumbnail.imageUrl;
		}

		return thumbnailURL;
	}

	render() {
		const { title, description } = this.props.data;
		const thumbnailStyle = this.makeThumbnail();

		let inStyle;
		if (this.props.isCarousel) {
			inStyle = {
				width: '236px',
				minWidth: '236px'
			};
		}

		return (
			<div className="bot_box">
				<div className="bot_txt item" style={inStyle}>
					{
						this.state.thumbnailURL && (
							<div
								className="img_box"
								style={thumbnailStyle}
								ref={(image) => {
									this.image = image;
								}}
							></div>
						)
						/*<div className="thumb_img"><img src={this.state.thumbnailURL} ref={image => {this.image = image}}/></div>*/
					}
					{title}
					<div className="text_area" dangerouslySetInnerHTML={{ __html: nl2br(description) }} />
					<ButtonItem data={this.props.data.buttons} fromApp={this.props.fromApp} />
				</div>
			</div>
		);
	}
}

export default BasicCard;
