import { call, put, fork, takeLatest } from 'redux-saga/effects'
import * as ActionTypes from '../modules/ActionTypes';
import getRealtimeAnswer from '../services/realtimeAnswer';

function* callGetRealtimeAnswer(action) {
    const { response, error} =  yield call(getRealtimeAnswer, action.payload);

    if (response) {

        yield put({type: ActionTypes.GET_REALTIME_ANSWER.SUCCESS, payload: { response }});
    } else {
        console.error("[ERROR][getRealtimeAnswer]", error)
    }
}

function* watchGet() {

    yield takeLatest(ActionTypes.GET_REALTIME_ANSWER.REQUEST, callGetRealtimeAnswer);
}

export default function* realtimeAnswerSaga() {

    yield fork(watchGet);
}