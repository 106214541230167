import React, { PureComponent } from 'react';

class AgreeContent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
           "defaultTxt" : "1. 목적 : 참여자 경품(기프티콘) 추첨 및 발송<br>2. 항목 : 포탈ID, 성명 및 연락처(ID연계)\n3. 보유기간 : 경품(기프티콘) 추첨 및 발송 후 6개월"
        }
        this.handleClickClose = this.handleClickClose.bind(this);
    }

    componentDidMount() {

    }

    handleClickClose() {
        this.props.close()
    }
    /*
        ----------------------------------------------------------------
        ** 개인정보 수집 및 이용 동의 ***
        1. 목적 : 참여자 경품(기프티콘) 추첨 및 발송
        2. 항목 : 포탈ID, 성명 및 연락처(ID연계)
        3. 보유기간 : 경품(기프티콘) 추첨 및 발송 후 6개월
        ※ 미동의 시 불이익 : 개인정보 수집 및 이용 동의를 하지 않을 경우 경품 추첨 및 선정 대상에 포함되지 않습니다.
        ※ 참여자 추첨 경품 : 아메리카노(스타벅스) 커피 기프티콘 30명
        ----------------------------------------------------------------
    */
    render() {
        const innerStyle = {
            zIndex:"103"
        }
        const innerBlue = {
            color : "blue"
        }
        return (
            <div className="layer_wrap layer_wrap_type2 advisor_layer_wrap agreement" style={innerStyle}>
            <div className="layer_inner">
            <div className="layer_cont">
                <h2 className="layer_h_tit" >개인정보 수집 및 이용 동의</h2>
                <div className="agreeText">
                1. 목적 : 참여자 경품(기프티콘) 추첨 및 발송<br/>2. 항목 : 포탈ID, 성명 및 연락처(ID연계)<br/>3. 보유기간 : 경품(기프티콘) 추첨 및 <br/>&nbsp;&nbsp;&nbsp;&nbsp;발송 후 <strong style={innerBlue}>6개월</strong>
                </div>
                <p className="layer_txt">※ 미 동의 시 불이익 : 개인정보 수집 및 이용 동의를 하지 <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;않을 경우 경품 추첨 및 선정 대상에 포함되지 않습니다.</p>
                <p className="layer_txt">※ 참여자 추첨 경품 : <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;아메리카노(스타벅스) 커피 기프티콘 100명</p>
                <div className="btn_area">
                    <button type="button" className="layer_btn" onClick={this.handleClickClose}>확인</button>
                </div>
            </div>
            </div>
            </div>
        );
    }
};

export default AgreeContent;




