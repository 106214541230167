import { createAction } from 'redux-actions';
import * as ActionTypes from './ActionTypes';

export const writeFeedback = createAction(ActionTypes.WRITE_FEEDBACK.REQUEST);
export const clearFeedbackAlert = createAction(ActionTypes.CLEAR_FEEDBACK_ALERT);

const initialState = {
    alertMessage : '',
}
// : '소중한 의견이 접수되었습니다.'
function feedback(state = initialState, action) {
    //console.info('[reducers.message.message][action]', action)
    switch(action.type) {
        case ActionTypes.WRITE_FEEDBACK.REQUEST:
            return action.payload
        case ActionTypes.WRITE_FEEDBACK.SUCCESS:
            let detail = '의견 접수에 오류가 발생했습니다. <br/> 잠시 후 다시 시도 바랍니다.'
            if (action.payload.response.data.code === 0) {
                detail = '소중한 의견이 접수되었습니다.'
            }
            return {
                alertMessage : detail
            }
        case ActionTypes.WRITE_FEEDBACK.FAILURE: 
            return action.payload.error
        case ActionTypes.CLEAR_FEEDBACK_ALERT: // 얼럿 후에 초기화
            return {
                alertMessage : ''
            }
        default:
            return state;
    }
}

export default feedback;